/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import LoginForm from "components/LoginForm";
import { useErrorParams } from "helpers/use-error-params";

import styles from "./styles.scss";

const LoginView = () => {
  const { content: { loginview } } = useContext(StoreInfoContext);
  useErrorParams({ "bad_loginkey": "errorInvalidLogin" });

  return (
    <>
      <Helmet title={loginview.pageTitle} />
      <LoginForm className={styles.login_form} />
    </>
  );
};

export default LoginView;
