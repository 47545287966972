/* @flow */

import type { InfoBlock } from "shop-state/types";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import { Title, Item } from "components/UiComponents";
import { useTranslate } from "@awardit/react-use-translate";
import { getNumberBasedOnBrowserWidth } from "helpers/utils";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import Button from "components/Button";
import Carousel from "components/Carousel";
import { DummyCard } from "components/ProductCard";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  infoArray: $ReadOnlyArray<InfoBlock>,
  currInfoNum: number,
  adaptiveHeightLimit?: number,
  loading: boolean,
};

const NewsCarousel = ({ infoArray, currInfoNum, adaptiveHeightLimit, loading }: Props) => {
  const t = useTranslate();
  const { width: browserWidth } = useBrowserDimensions();
  const numNews = getNumberBasedOnBrowserWidth(styles, { ts: 1, medium: 2 }, 3, browserWidth);
  const { routes, content: { homeview: content } } = useContext(StoreInfoContext);
  const adaptiveHeight = true;
  const title = content[`currentInfo${currInfoNum}Title`];
  const ctaRoute = routes[`currentInfoView${currInfoNum}`].url;

  return (
    loading === false ?
      <>
        <div className={styles.header}>
          <Title className={styles.title} elem="h2">{title}</Title>
          <Button className={styles.cta} variant="primaryAlt" to={ctaRoute}>
            Se alla
          </Button>
        </div>
        <div className={styles.currenInfoCarouselOuterContainer}>
          <Carousel
            className={styles.carousel}
            adaptiveHeight={adaptiveHeight}
            adaptiveHeightLimit={adaptiveHeightLimit}
            items={infoArray.map((info, i) => (
              <div key={`currentinfo${currInfoNum}_item_${i}`} className={styles.currentInfoItem} idx={i}>
                <div className={cn(styles.currentInfoCard, "awardit-currenInfoCard")}>
                  <div className={styles.currentInfoCardShadow} />
                  <div className={styles.currentInfoCardContent}>
                    {info.image && (
                      <div className={styles.currentInfoCardContentImage}>
                        <img src={info.image} />
                      </div>
                    )}
                    <div className={styles.currentInfoCardContentText}>
                      {info.title && <h3>{info.title}</h3>}
                      {/* eslint-disable react/no-danger */}
                      {info.content && (
                        <div
                          className={cn(styles.currentInfoCardContentDescription, "awardit-currentInfoDescription")}
                          dangerouslySetInnerHTML={{ __html: info.description }}
                        />
                      )}
                      {/* eslint-enable react/no-danger */}
                    </div>
                    <div className={styles.currentInfoCardContentMeta}>
                      <Link
                        className={styles.link}
                        to={{
                          pathname: `${ctaRoute}/${info.id}`,
                          state: { hint: { title: info.title } },
                        }}
                      >
                        <span className={cn(styles.readMore, "link")}>{t("COMMON.READ_MORE")}</span>
                      </Link>
                      {(info.tags && info.tags.length > 0) ?
                        <div className={styles.tags}>
                          {info.tags.map((tag, i) => (
                            <span key={i} className={styles.tag}>{tag}</span>
                          ))}
                        </div> : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            slidesToScroll={numNews}
            slidesToShow={numNews}
            timer={60000}
          />
        </div>
      </> : <DummyNewsCarousel />
  );
};

const DummyNewsCarousel = () => {
  const { width: browserWidth } = useBrowserDimensions();
  const numNews = getNumberBasedOnBrowserWidth(styles, { ts: 1, medium: 2 }, 3, browserWidth);

  return (
    <div className={styles.dummy}>
      <Title className={styles.title} elem="h2">&nbsp;</Title>
      <div className={styles.currenInfoCarouselOuterContainer}>
        <Carousel
          className={styles.carousel}
          items={Array.from({ length: numNews }).map((_, i) => (
            <Item key={i} className={styles.currentInfoItem}>
              <DummyCard className={styles.currentInfoCard} />
            </Item>
          ))}
          slidesToScroll={numNews}
          slidesToShow={numNews}
          timer={60000}
        />
      </div>
    </div>
  );
};

export default NewsCarousel;
