
/* @flow */

import type { Mode } from "state/view-mode";

import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import { MODE } from "state/view-mode";
import SearchMenu from "components/AppHeader/SearchMenu";
import Categories from "components/AppHeader/Large/Categories";

import styles from "./styles.scss";

type Props = {
  mode: Mode,
  setMode: Mode => void,
};

const Shop = ({ mode, setMode }: Props) => {
  const toggleCategories = () => setMode(mode === MODE.CATEGORIES ? MODE.NORMAL : MODE.CATEGORIES);
  const { categories, routes } = useContext(StoreInfoContext);

  return (
    <div className={styles.submenu}>
      <div className={styles.links}>
        <span
          className={
            cn(
              styles.item,
              styles.item__categories,
              { [styles.active]: mode === MODE.CATEGORIES }
            )
          }
          onClick={toggleCategories}
        >

          {routes.categoriesView.title}

          {mode === MODE.CATEGORIES &&
          <nav className={styles.categories}>
            <Categories categories={categories} />
          </nav>
          }

        </span>

        <Link
          className={
            cn(
              styles.item,
              styles.item__categories,
              styles.brands
            )
          }
          to={{
            pathname: routes.brandsView.url,
          }}
        >
          {routes.brandsView.title}
        </Link>
      </div>

      <span
        className={
          cn(
            styles.item,
            styles.item__search
          )
        }
        onClick={() => setMode(MODE.SEARCH)}
      >
        <nav className={styles.search}>
          <SearchMenu />
        </nav>
      </span>
    </div>
  );
};

export default Shop;
